import { cropResizeSrc } from "components/shared/BlossomImage/BlossomImage";

export const optimizeEditorialBannerImageUrl = (
  imageUrl: string,
  isMobile?: boolean,
  minWidthLarge: number = 1400,
  minHeightLarge: number = 600
): string => {
  return isMobile
    ? cropResizeSrc(imageUrl, { width: 450, height: 600 }, "high")
    : cropResizeSrc(imageUrl, { width: minWidthLarge, height: minHeightLarge }, "high");
};
