import * as React from "react";

import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";

import { EGDSPrimaryButton, EGDSSecondaryButton, EGDSButtonSize } from "@egds/react-core/button";
import { EGDSSpacing, EGDSSpacingProps } from "@egds/react-core/spacing";
import { EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { EGDSHeading, EGDSSubheading, EGDSText } from "@egds/react-core/text";
import { EGDSLayoutFlexProps } from "@egds/react-core/layout-flex";
import { EGDSLayoutConditionalGridTrack, EGDSLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { EGDSUnformattedHtml, EGDSUnformattedHtmlReplaceDefaults } from "@egds/react-core/unformatted-html";

import { EditorialFlexModule, FlexModuleResult } from "typings/microserviceModels/content-flex-module";

import { ScrimCard } from "components/shared/ScrimCard/ScrimCard";
import { EGDSScrimType, EGDSScrimTypes } from "@egds/react-core/scrim";
import { FAILED_MODEL_RENDER } from "src/config/systemEvents";
import { NOOP_LOGGER as logger } from "bernie-logger";

import { observer } from "mobx-react";
import { withStores } from "stores";
import { SpacingSize } from "src/components/utility/FlexAttributesUtil";
import { EGClickStreamTracker } from "src/components/utility/analytics/EGClickStream/EGClickStreamTracker";
import { optimizeEditorialBannerImageUrl } from "components/flexComponents/EditorialBanner/EditorialBannerUtils";
import { EditorialBannerProps } from "./typings";

export const EditorialBanner = withStores("flexModuleModelStore")(
  observer((props: EditorialBannerProps) => {
    try {
      const { useRef, useEffect, useState } = React;
      const { templateComponent, flexModuleModelStore, isHero } = props;
      const { metadata, config } = templateComponent;
      const model = flexModuleModelStore.getModel(metadata.id) as FlexModuleResult;
      const { fmId } = config;
      const scrimRef = useRef<HTMLElement>(null);
      const [isSmallScrim, setSmallScrim] = useState(false);
      const smallLimitWidth = 800;

      useEffect(() => {
        if (!isSmallScrim && scrimRef && scrimRef.current && scrimRef.current.offsetWidth < smallLimitWidth) {
          setSmallScrim(true);
        } else if (isSmallScrim) {
          setSmallScrim(false);
        }
      }, []);

      /* istanbul ignore next*/
      if (!model) {
        return null;
      }

      // @ts-ignore
      const [{ media, text }] = model.items;
      const { padding, textTheme = "inverse", minWidthLarge, minHeightLarge } = model as EditorialFlexModule;
      /**
       * This is a temporary work around due to limitations of the call-to-action model that will be addressed in the future.
       * Parsing data from contributed HTML content should not be considered an accepted approach to be repeated elsewhere
       */
      const htmlText = text as string;

      const [, title] = /<h3>(.*?)<\/h3>/g.exec(htmlText) || [];
      const htmlTitle = title && `<h2 dataTestId="title">${title}</h2>`;
      const [, subtitle] = /<p class="subtitle">(.*?)<\/p>/g.exec(htmlText) || [];
      const htmlSubtitle = subtitle && `<div dataTestId="subtitle">${subtitle}</div>`;
      const [, ctaLabel] = /<p><button>(.*?)<\/button><\/p>/g.exec(htmlText) || [];
      const htmlCtaLabel = ctaLabel && `<div dataTestId="cta-label">${ctaLabel}</div>`;
      const [, subtitleTerm] = /<p class="subtitle-term">(.*?)<\/p>/g.exec(htmlText) || [];
      const htmlSubtitleTerm = subtitleTerm && `<div dataTestId="subtitle-term">${subtitleTerm}</div>`;

      // Media content
      const [desktopMedia, mobileMedia] = media;
      const { mediaUrl: desktopMediaURL, mediaAlt: desktopMediaAtlText } = desktopMedia;
      const { mediaUrl: mobileMediaURL, mediaAlt: mobileMediaAtlText } = mobileMedia;
      const { clickUrl } = desktopMedia;

      const optimizedDesktopImageURL = optimizeEditorialBannerImageUrl(
        desktopMediaURL,
        false,
        minWidthLarge,
        minHeightLarge
      );
      const optimizedMobileImageURL = optimizeEditorialBannerImageUrl(mobileMediaURL, true);

      // Component display properties
      const hasNoPadding = padding === SpacingSize.NONE;
      const spacing: EGDSSpacingProps = { margin: { inline: "three", blockstart: hasNoPadding ? "unset" : "three" } };

      const justifyContent: EGDSLayoutFlexProps["justifyContent"] = "center";

      const mainGridColumns: EGDSLayoutConditionalGridTrack = {
        small: 1,
        medium: isHero ? 1 : 2,
        large: isHero ? 3 : 2,
      };
      const headingCols: EGDSLayoutConditionalGridSpan = { small: 1, medium: isSmallScrim || isHero ? 1 : 2 };

      const CtaButton = EGClickStreamTracker(isHero ? EGDSPrimaryButton : EGDSSecondaryButton);
      const largeFontSizeTitle = isHero ? 800 : 700;
      const smallFontSizeTitle = isHero ? 700 : 600;
      const fontSizeSubtitle = isHero || isSmallScrim ? 400 : 500;
      const fontSizeTerm = 200;
      const buttonCtaSize = isHero ? EGDSButtonSize.LARGE : EGDSButtonSize.MEDIUM;
      const interiorSpacing: EGDSSpacingProps = {
        padding: { inline: isHero && !isSmallScrim ? "twelve" : "six" },
      };
      const moduleName = isHero ? "EditorialHeroBanner" : "EditorialBanner";

      const titleSlotRender = (scrimType: EGDSScrimTypes) => {
        return scrimType === EGDSScrimType.BOTTOM ? (
          <EGDSUnformattedHtml
            elementsToReplace={{
              ...EGDSUnformattedHtmlReplaceDefaults,
              h2: ({ children, ...props }) => (
                <EGDSSubheading tag="h2" {...props}>
                  {children}
                </EGDSSubheading>
              ),
            }}
            htmlToParse={htmlTitle}
          />
        ) : (
          <EGDSUnformattedHtml
            elementsToReplace={{
              ...EGDSUnformattedHtmlReplaceDefaults,
              h2: ({ children, ...props }) => (
                <EGDSHeading tag="h2" {...props}>
                  <EGDSText size={isSmallScrim ? smallFontSizeTitle : largeFontSizeTitle} theme={textTheme}>
                    {children}
                  </EGDSText>
                </EGDSHeading>
              ),
            }}
            htmlToParse={htmlTitle}
          />
        );
      };

      const subtitleSlotRender = (
        <EGDSUnformattedHtml
          elementsToReplace={{
            ...EGDSUnformattedHtmlReplaceDefaults,
            div: ({ children, ...props }) => (
              <EGDSText size={fontSizeSubtitle} theme={textTheme} {...props}>
                {children}
              </EGDSText>
            ),
          }}
          htmlToParse={htmlSubtitle}
        />
      );

      const subtitleTermRender = (
        <EGDSUnformattedHtml
          elementsToReplace={{
            ...EGDSUnformattedHtmlReplaceDefaults,
            div: ({ children, ...props }) => (
              <EGDSText data-testid="editorial-banner-term" size={fontSizeTerm} theme={textTheme} {...props}>
                {children}
              </EGDSText>
            ),
          }}
          htmlToParse={htmlSubtitleTerm}
        />
      );

      const buildScrimCardProps = (scrimType: EGDSScrimTypes) => ({
        id: `editorial-banner-${fmId}`,
        className: "EditorialBanner",
        titleSlot: titleSlotRender(scrimType),
        subtitleSlot: subtitleSlotRender,
        footerSlot: (
          <EGDSSpacing margin={{ blockend: "one" }}>
            <div>
              <EGDSSpacing margin={{ block: "three" }}>
                <div>
                  <EGDSUnformattedHtml
                    elementsToReplace={{
                      ...EGDSUnformattedHtmlReplaceDefaults,
                      div: ({ children }) => (
                        <CtaButton
                          moduleName={moduleName}
                          linkName="CTA button clicked"
                          rfrr={`home.${moduleName}.${metadata.id}`}
                          size={buttonCtaSize}
                          tag="a"
                          href={clickUrl}
                        >
                          {children}
                        </CtaButton>
                      ),
                    }}
                    htmlToParse={htmlCtaLabel}
                  />
                </div>
              </EGDSSpacing>
              {subtitleTerm && subtitleTermRender}
            </div>
          </EGDSSpacing>
        ),
        spacing,
        mainGridColumns,
        padding,
        headingCols,
        interiorSpacing,
      });

      const largeAspectRatio =
        isHero || isSmallScrim ? EGDSFigureAspectRatioType.R21_9 : EGDSFigureAspectRatioType.R4_1;

      return (
        <Viewport>
          <ViewSmall>
            <ScrimCard
              {...buildScrimCardProps(EGDSScrimType.BOTTOM)}
              aspectRatio={isHero ? EGDSFigureAspectRatioType.R3_4 : EGDSFigureAspectRatioType.R1_1}
              backgroundImageURL={optimizedMobileImageURL}
              altText={mobileMediaAtlText}
              scrimType={EGDSScrimType.BOTTOM}
              justifyContent={isSmallScrim ? "end" : justifyContent}
              domRef={scrimRef}
            />
          </ViewSmall>
          <ViewMedium>
            <ScrimCard
              {...buildScrimCardProps(EGDSScrimType.OVERLAY)}
              aspectRatio={EGDSFigureAspectRatioType.R21_9}
              backgroundImageURL={optimizedDesktopImageURL}
              altText={desktopMediaAtlText}
              scrimType={EGDSScrimType.OVERLAY}
              domRef={scrimRef}
              justifyContent={justifyContent}
              verticallyCenter
            />
          </ViewMedium>
          <ViewLarge>
            <ScrimCard
              {...buildScrimCardProps(EGDSScrimType.OVERLAY)}
              aspectRatio={largeAspectRatio}
              backgroundImageURL={optimizedDesktopImageURL}
              altText={desktopMediaAtlText}
              scrimType={EGDSScrimType.OVERLAY}
              domRef={scrimRef}
              justifyContent={justifyContent}
              verticallyCenter
            />
          </ViewLarge>
        </Viewport>
      );
    } catch (error) {
      logger.logEvent(FAILED_MODEL_RENDER, "Unexpected error occurred while rendering EditorialBanner module.", error);

      return null;
    }
  })
);

export default EditorialBanner;
